import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { ErrorType } from '@app/core/models/error-types';
import { SentryService } from '@app/core/services/sentry.service';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor(private readonly _sentryService: SentryService) {}

  extractError(error: ErrorType): string | Error | HttpErrorResponse {
    // Try to unwrap zone.js error.
    // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
    if (error?.ngOriginalError) {
      error = error.ngOriginalError;
    }

    // We can handle messages and Error objects directly.
    if (typeof error === 'string' || error instanceof Error) {
      return error;
    }

    // If it's http module error, extract as much information from it as we can.
    if (error instanceof HttpErrorResponse) {
      return this._extractHttpErrorResponse(error);
    }

    // The above code doesn't always work since 'instanceof' relies on the object being created with the 'new' keyword
    if (error.error?.message) {
      return error.error.message;
    }
    if (error.message) {
      return error.message;
    }

    // Skip if there's no error, and let user decide what to do with it.
    return;
  }

  handleError(error: ErrorType): void {
    const extractedError = this.extractError(error) || 'Handled unknown error';
    this._sentryService.captureException(extractedError);
  }

  private _extractHttpErrorResponse(error: HttpErrorResponse): string | Error | HttpErrorResponse {
    // The `error` property of http exception can be either an `Error` object, which we can use directly...
    if (error.error instanceof Error) {
      return error.error;
    }

    // ... or an`ErrorEvent`, which can provide us with the message but no stack...
    if (error.error instanceof ErrorEvent) {
      return error.error.message;
    }

    // ...or the request body itself, which we can use as a message instead.
    if (typeof error.error === 'string') {
      return `Server returned code ${error.status} with body "${error.error}"`;
    }

    // If we don't have any detailed information, fallback to the request message itself.
    return error;
  }
}
