import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { BackendRequestAdapter, BackendResponseAdapter } from '../adapters';
import { Project } from '../models';
import { BackendProject } from '../models/backend-http-request';

const PROJECT_ENDPOINT = `${environment.backend_url}project`;

@Injectable({
  providedIn: 'root',
})
export class BackendService {
  constructor(private readonly _httpClient: HttpClient) {}

  getUserProjects(userId: string): Observable<Project[]> {
    return this._httpClient
      .get<BackendProject[]>(PROJECT_ENDPOINT, { params: { UserId: userId } })
      .pipe(map(userProjects => userProjects.map(project => BackendResponseAdapter.projectResponseToProject(project))));
  }

  addProject(project: Project): Observable<Project> {
    return this._httpClient
      .post<BackendProject>(PROJECT_ENDPOINT, BackendRequestAdapter.projectToProjectRequest(project))
      .pipe(map(backendResponse => BackendResponseAdapter.projectResponseToProject(backendResponse)));
  }

  updateProject(project: Project): Observable<Project> {
    const projectEndpoint = `/${project.id}`;
    return this._httpClient
      .put<BackendProject>(
        `${PROJECT_ENDPOINT}${projectEndpoint}`,
        BackendRequestAdapter.projectToProjectRequest(project)
      )
      .pipe(map(backendResponse => BackendResponseAdapter.projectResponseToProject(backendResponse)));
  }

  deleteProject(projectId: number): Observable<number> {
    return this._httpClient
      .delete<BackendProject>(`${PROJECT_ENDPOINT}/${projectId}`)
      .pipe(map(deletedProject => deletedProject.id));
  }
}
