import { UserRole } from '@app/core/const/user';
import * as fromEntities from '@app/core/entities/reducers';
import * as fromRouter from '@ngrx/router-store';
import { ActionReducerMap, createSelector } from '@ngrx/store';

import * as fromApp from './app.reducer';
import * as fromAuth from './auth.reducer';
import * as fromImports from './imports.reducer';

export type State = {
  app: fromApp.AppState;
  auth: fromAuth.AuthState;
  imports: fromImports.ImportsState;
  entities: fromEntities.State;
  router: fromRouter.RouterReducerState;
};

export const rootReducers: ActionReducerMap<State> = {
  app: fromApp.reducer,
  auth: fromAuth.reducer,
  imports: fromImports.reducer,
  entities: fromEntities.reducers,
  router: fromRouter.routerReducer,
};

export const getAppState = (state: State): fromApp.AppState => state.app;
export const getAuthState = (state: State): fromAuth.AuthState => state.auth;
export const getImportsState = (state: State): fromImports.ImportsState => state.imports;

export const getDisclaimerToDisplay = createSelector(
  getAppState,
  (state: fromApp.AppState) => state.disclaimerToDisplay
);

export const getCurrentLang = createSelector(getAppState, (state: fromApp.AppState) => state.currentLang);

export const getCountry = createSelector(getAppState, (state: fromApp.AppState) => state.country);

export const getUpdateEntitiesAt = createSelector(getAppState, (state: fromApp.AppState) => state.updateEntitiesAt);

export const getExportAnalysisRunning = createSelector(
  getAppState,
  (state: fromApp.AppState) => state.exportAnalysisRunning
);

export const getIsScreenWide = createSelector(getAppState, (state: fromApp.AppState) => state.isScreenWide);

export const getCurrentUser = createSelector(getAuthState, (state: fromAuth.AuthState) => state.currentUser);

export const getCurrentUserIsAdmin = createSelector(getCurrentUser, currentUser =>
  currentUser.roles.includes(UserRole.admin)
);

export const getCurrentUserUsername = createSelector(getCurrentUser, currentUser => currentUser.nameId.split('@')[0]);

export const getAuthenticationLoading = createSelector(getAuthState, (state: fromAuth.AuthState) => state.loading);

export const getAllImports = createSelector(getImportsState, (state: fromImports.ImportsState) => state);

export const getAllImportsDates = createSelector(getImportsState, (state: fromImports.ImportsState) => ({
  covariancesViews: state.covariancesViews.date,
  namesClassifications: state.namesClassifications.date,
  forexHistories: state.forexHistories.date,
}));

export const getCovariancesViewsDate = createSelector(
  getImportsState,
  (state: fromImports.ImportsState) => state.covariancesViews.date
);

export const getNamesClassificationsDate = createSelector(
  getImportsState,
  (state: fromImports.ImportsState) => state.namesClassifications.date
);

export const getForexHistoriesDate = createSelector(
  getImportsState,
  (state: fromImports.ImportsState) => state.forexHistories.date
);
