import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { AuthenticationResponse } from '../models/authentication';

export const AUTH_ENDPOINT = `${environment.authentication}auth`;
export const LOGOUT_ENDPOINT = `${environment.authentication}auth/logout`;
export const authTokenKey = 'auth_token';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private readonly _httpClient: HttpClient) {}

  authenticateUser(samlResponse: string): Observable<AuthenticationResponse> {
    return this._httpClient.post<AuthenticationResponse>(AUTH_ENDPOINT, { samlResponse });
  }
}
