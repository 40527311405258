import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Injectable()
export class SidenavService {
  private _sidenav: MatSidenav;

  setSidenav(sidenav: MatSidenav): void {
    this._sidenav = sidenav;
  }

  open(): void {
    this._sidenav.open();
  }

  close(): void {
    this._sidenav.close();
  }

  toggle(): void {
    this._sidenav.toggle();
  }
}
