import { Injectable } from '@angular/core';
import { Allocation, Asset, BacktestResult, CsvExportAllocation, CsvExportPosition, Project } from '@app/core/models';
import { downloadFile } from '@app/core/tools/file-saver';
import { Parser, transforms as json2csvTransforms } from 'json2csv';
import { orderBy } from 'lodash';

import { csvExportProjectFields } from '../const/csv-export';
import { AvailableLang } from '../const/i18n';
import { isAssetCash, isAssetCredit } from '../tools/smart-risk.tools';

@Injectable({
  providedIn: 'root',
})
export class CsvExportService {
  exportProjectAsCsv(project: Project, assets: Asset[], currentLang: AvailableLang): void {
    const {
      id,
      userId,
      name,
      currency,
      creditRate,
      financialProfile,
      riskProfile,
      hedge: hedging,
      allocations,
      backtestResults,
      backtestParameters,
    } = project;
    const asset = assets.find(assetItem => assetItem.id === backtestParameters.benchmark.positions[0].instrumentId);

    const csvExportProject = {
      id,
      userId,
      name,
      currency,
      creditRate,
      hedging,
      financialProfile,
      allocations: this._getCsvAllocationData(allocations, backtestResults, assets, currentLang),
      riskProfile,
      backtestParameters: { ...backtestParameters, assetName: asset.name[currentLang] },
    };
    const transforms = [
      json2csvTransforms.unwind({
        paths: ['allocations', 'allocations.csvData', 'allocations.backtestResults'],
        blankOut: true,
      }),
    ];

    const parser = new Parser({ delimiter: ';', fields: csvExportProjectFields, transforms, withBOM: true });
    const csv = parser.parse(csvExportProject);
    downloadFile(csv, 'text/csv', `${project.name}.csv`);
  }

  private _getCsvAllocationData(
    allocations: Allocation[],
    backtestResults: BacktestResult[],
    assets: Asset[],
    currentLang: AvailableLang
  ): CsvExportAllocation[] {
    return backtestResults.map((backtestResultItem, index) => {
      let positions: CsvExportPosition[] = [];
      const allocation = allocations[index] ? allocations[index] : undefined;
      const allocationResult = allocation
        ? {
            ...allocation.exAnteAnalysis.allocationResult,
            normalVar: -allocation.exAnteAnalysis.allocationResult.normalVar,
          }
        : undefined;
      const csvDataLength = allocation
        ? Math.max(
            ...[
              allocation.positions.length,
              allocation.exAnteAnalysis.liquidity.length,
              backtestResultItem.portfolioReturns.length,
            ]
          )
        : backtestResultItem.portfolioReturns.length;

      if (allocation) {
        const positionIds = { cash: 0, credit: 1 };
        positions = allocation.positions.map(position => {
          const asset = assets.find(assetItem => assetItem.id === position.instrumentId);
          let positionId = asset.id + Object.keys(positionIds).length;
          if (isAssetCash(asset)) {
            positionId = positionIds.cash;
          }
          if (isAssetCredit(asset)) {
            positionId = positionIds.credit;
          }
          return {
            assetName: asset ? asset.name[currentLang] : 'Asset not found',
            positionId,
            positionValue: position.positionValue,
          };
        });
      }

      const sortedPosition = orderBy(positions, 'positionId', 'asc');
      const csvData = Array.from({ length: csvDataLength }, (_, key) => {
        const position = sortedPosition[key] ? sortedPosition[key] : undefined;
        let liquidity;
        let backtestResult;

        if (allocation?.exAnteAnalysis.liquidity[key]) {
          liquidity = {
            liquidityLabel: allocation.exAnteAnalysis.liquidity[key].label,
            liquidityValue: allocation.exAnteAnalysis.liquidity[key].value,
          };
        }
        if (backtestResultItem.portfolioReturns[key]) {
          backtestResult = {
            backtestResultDate: backtestResultItem.portfolioReturns[key].date,
            backtestResultValue: backtestResultItem.portfolioReturns[key].value,
          };
        }

        return {
          ...position,
          ...liquidity,
          ...backtestResult,
        };
      });

      return {
        id: backtestResultItem.allocationId,
        name: allocation ? allocation.name : 'Benchmark',
        allocationResult,
        backtestMaxDrawDown: -backtestResultItem.maxDrawDown,
        csvData,
      };
    });
  }
}
