import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthActions } from '@app/store/actions';
import * as fromRoot from '@app/store/reducers';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserRole } from '../const/user';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  constructor(
    private readonly _router: Router,
    private readonly _store: Store<fromRoot.State>
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this._store.select(fromRoot.getCurrentUser).pipe(
      map(currentUser => {
        if (!currentUser) {
          this._router.navigate(['/login']);
        }
        const requiredRole: UserRole = route.data.role;
        const userAllowed = !!currentUser.roles.includes(requiredRole);
        if (!userAllowed) {
          if (route.routeConfig.path === '') {
            this._store.dispatch(AuthActions.notAuthorized({}));
          } else {
            this._store.dispatch(AuthActions.forbidden());
          }
        }
        return currentUser && userAllowed;
      })
    );
  }
}
