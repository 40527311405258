export const palette = {
  white: { 400: '#ffffff', 500: '#f9f9f9', 700: '#ebeef0', 800: '#efeff4', 900: '#e5ebe9' },
  grey: { 50: '#dee5ea', 100: '#a5a5a5', 300: '#8e8e93', 400: '#5e6261', 600: '#2c2c2c' },
  green: { 50: '#99b0a6', 100: '#7f9c90', 200: '#6c8578', 300: '#00816d', 400: '#006a4e' },
  purple: '#642f6c',
  orange: '#c16c18',
  blue: { 400: '#006a8e', 500: '#1d4e89' },
  blueGreen: '#68bbae',
  c13: '#84d87a',
  c14: '#d87a7a',
};

export const paletteAllocationsGreens = [palette.green[50], palette.green[200], palette.green[300]];
export const liquidityPalette = ['#f2f2f2', '#bfbfbf', '#a6a6a6', '#7f7f7f'];
