export enum AvailableCountry {
  fra = 'FRA',
  bel = 'BEL',
  asia = 'ASIA',
  spa = 'SPA',
  ch = 'CH',
  lux = 'LUX',
}

export const AVAILABLE_COUNTRIES: AvailableCountry[] = Object.values(AvailableCountry);
