import { Injectable } from '@angular/core';
import { ImporterService } from '@app/core/services';
import { isoStringDateFormat } from '@app/core/tools/date.tools';
import { AppActions, ImportsActions } from '@app/store/actions';
import * as fromRoot from '@app/store/reducers';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { isEmpty, isEqual } from 'lodash';
import moment from 'moment';
import { merge, of, throwError } from 'rxjs';
import { catchError, delay, map, switchMap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class ImportsEffects {
  getAllImportsDate$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ImportsActions.getAllImportsDates),
      withLatestFrom(this._store.select(fromRoot.getAllImportsDates)),
      switchMap(([_, previousImportsDates]) =>
        this._importerService.getAllLatestImports().pipe(
          switchMap(allImportsDates => {
            const actions: Array<TypedAction<string>> = [ImportsActions.getAllImportsDatesSuccess({ allImportsDates })];
            const entitiesNeedUpdate =
              Object.values(previousImportsDates).find(date => !!date) &&
              !isEqual(previousImportsDates, allImportsDates);
            if (entitiesNeedUpdate) {
              const updateEntitiesAt = this._getUpdateEntitiesAt();
              actions.push(AppActions.setUpdateEntitiesAt({ updateEntitiesAt }));
            }
            return actions;
          }),
          catchError(error =>
            this._translocoService.selectTranslate('error.latest-import', {}, 'admin').pipe(
              switchMap(errorLabel => {
                this._store.dispatch(ImportsActions.getAllImportsDatesFailure({ errorLabel }));
                return throwError(error);
              })
            )
          )
        )
      )
    )
  );

  getOneImportDate$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ImportsActions.getOneImportDate),
      switchMap(action =>
        this._importerService.getLatestImport(action.uploadedFile).pipe(
          map(date => ImportsActions.getOneImportDateSuccess({ uploadedFile: action.uploadedFile, date })),
          catchError(error =>
            this._translocoService.selectTranslate('error.latest-import', {}, 'admin').pipe(
              switchMap(errorLabel => {
                this._store.dispatch(
                  ImportsActions.getOneImportDateFailure({ uploadedFile: action.uploadedFile, errorLabel })
                );
                return throwError(error);
              })
            )
          )
        )
      )
    )
  );

  importFile$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ImportsActions.importFile),
      switchMap(action => {
        return this._importerService.importFile(action.file, action.uploadedFile).pipe(
          switchMap(response => {
            if (isEmpty(response.errorMessages)) {
              const updateEntitiesAt = this._getUpdateEntitiesAt();
              return [
                ImportsActions.importFileSuccess({ uploadedFile: action.uploadedFile }),
                AppActions.setUpdateEntitiesAt({ updateEntitiesAt }),
              ];
            }
            return [ImportsActions.importFileFailure({ uploadedFile: action.uploadedFile })];
          }),
          catchError(error => {
            this._store.dispatch(ImportsActions.importFileFailure({ uploadedFile: action.uploadedFile }));
            return throwError(error);
          })
        );
      })
    )
  );

  importFileSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ImportsActions.importFileSuccess),
      switchMap(action =>
        merge(
          of(ImportsActions.getOneImportDate({ uploadedFile: action.uploadedFile })),
          of(ImportsActions.setRecentlyImported({ uploadedFile: action.uploadedFile, recentlyImported: false })).pipe(
            delay(this._recentlyImportedDelay)
          )
        )
      )
    )
  );

  private readonly _recentlyImportedDelay = 5000;
  private readonly _updateEntitiesAtDelay = 10000;

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store<fromRoot.State>,
    private readonly _importerService: ImporterService,
    private readonly _translocoService: TranslocoService
  ) {}

  private _getUpdateEntitiesAt(): string {
    return moment().add(this._updateEntitiesAtDelay).format(isoStringDateFormat);
  }
}
