import { Injectable } from '@angular/core';
import { liquidityPalette } from '@app/core/const/palette';
import * as fromEntities from '@app/core/entities/reducers';
import { SmartRiskService } from '@app/core/services';
import * as fromRoot from '@app/store/reducers';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { throwError } from 'rxjs';
import { catchError, filter, switchMap, withLatestFrom } from 'rxjs/operators';

import { AssetsActions, ProjectsActions } from '../actions';
import { getAllAssets } from '../reducers';

@Injectable()
export class AssetEffects {
  loadAssets$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AssetsActions.loadAssets),
      withLatestFrom(
        this._store.select(getAllAssets),
        this._store.select(fromEntities.getAllProjects),
        this._store.select(fromRoot.getCountry)
      ),
      switchMap(([_, currentAssets, projects, country]) =>
        this._smartRiskService.getAssets(country).pipe(
          switchMap(assets => {
            // use en, because it available on all country
            const orderedEnLiquidities = ['Very Low', 'Low', 'High', 'Very High'];

            const availableLiquidities = assets
              .map(a => a.liquidity)
              .filter((l, index, liquidities) => liquidities.findIndex(dup => dup.en === l.en) === index)
              .sort((a, b) => orderedEnLiquidities.indexOf(a.en) - orderedEnLiquidities.indexOf(b.en))
              .map((name, index) => ({
                name,
                color: liquidityPalette[index],
              }));
            const actionsToDispatch: Array<TypedAction<string>> = [
              AssetsActions.loadAssetsSuccess({ assets }),
              AssetsActions.setAvailableLiquidities({ availableLiquidities }),
            ];
            if (currentAssets.find(asset => asset.histories)) {
              actionsToDispatch.push(AssetsActions.loadAssetsHistories());
            }
            if (projects.length > 0) {
              actionsToDispatch.push(ProjectsActions.loadUserProjects());
            }
            return actionsToDispatch;
          }),
          catchError(error => throwError(error))
        )
      )
    )
  );

  loadAssetsHistories$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AssetsActions.loadAssetsHistories),
      withLatestFrom(this._store.select(getAllAssets)),
      filter(([_, assets]) => assets && assets.length > 0 && !assets.find(asset => asset.histories)),
      switchMap(([_, assets]) =>
        this._smartRiskService.getAssetsHistories().pipe(
          switchMap(assetsHistories => {
            const updatedAssets = assets.map(asset => {
              const assetHistories = assetsHistories.find(
                historiesItem => historiesItem.instrumentId === asset.id.toString()
              );
              if (assetHistories) {
                delete assetHistories.instrumentId;
              }
              return { id: asset.id, changes: { histories: assetHistories } };
            });
            return [AssetsActions.loadAssetsHistoriesSuccess({ assets: updatedAssets })];
          }),
          catchError(error => throwError(error))
        )
      )
    )
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store<fromRoot.State>,
    private readonly _smartRiskService: SmartRiskService
  ) {}
}
