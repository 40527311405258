import { User } from '@app/core/models';
import { Action, createReducer, on } from '@ngrx/store';

import { AuthActions } from '../actions';

export type AuthState = {
  currentUser: User;
  loading: boolean;
};

const initialState: AuthState = {
  currentUser: undefined,
  loading: false,
};

const authReducer = createReducer(
  initialState,
  on(AuthActions.authenticateUser, state => ({ ...state, loading: true })),
  on(AuthActions.authenticateUserSuccess, state => ({ ...state, loading: false })),
  on(AuthActions.authenticateUserFailure, state => ({ ...state, loading: false })),
  on(AuthActions.loginUserSuccess, (state, { currentUser }) => ({ ...state, currentUser })),
  on(AuthActions.logoutUserSuccess, state => ({ ...state, currentUser: undefined }))
);

export function reducer(state: AuthState | undefined, action: Action): AuthState {
  return authReducer(state, action);
}
