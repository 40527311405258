import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserRole } from '@app/core/const/user';
import { TelemetryEventTypes, User } from '@app/core/models';
import { AuthenticationToken } from '@app/core/models/authentication';
import { AUTH_ENDPOINT, authTokenKey, LoginService, LOGOUT_ENDPOINT } from '@app/core/services';
import { SnackBarService } from '@app/shared/services';
import { AppActions, AuthActions } from '@app/store/actions';
import * as fromRoot from '@app/store/reducers';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { jwtDecode } from 'jwt-decode';
import moment from 'moment';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class AuthEffects {
  authenticateUser$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthActions.authenticateUser),
      switchMap(({ samlResponse }) => {
        return this._loginService.authenticateUser(samlResponse).pipe(
          map(authData => {
            localStorage.setItem(authTokenKey, authData.jwtToken);
            return AuthActions.loginUser();
          })
        );
      })
    )
  );

  authenticateRedirect$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(AuthActions.authenticateRedirect),
        withLatestFrom(this._store.select(fromRoot.getCountry)),
        tap(([_, country]) => {
          window.location.href = `${AUTH_ENDPOINT}?relayState=${country}`;
        })
      ),
    { dispatch: false }
  );

  loginUser$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthActions.loginUser),
      map(() => {
        const authToken = this._getTokenWithValidDate();
        if (!authToken) {
          return AuthActions.authenticateRedirect();
        }
        const currentUser = {
          nameId: authToken.nameid,
          email: authToken.email,
          roles: Object.values(UserRole).filter(role => authToken.role.includes(role)),
        } as User;
        return AuthActions.loginUserSuccess({ currentUser });
      })
    )
  );

  loginUserSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthActions.loginUserSuccess),
      map(() => AppActions.sendTelemetryEvent({ telemetryData: { eventType: TelemetryEventTypes.userLogin } })),
      tap(() => {
        this._router.navigate(['/']);
      })
    )
  );

  logoutUser$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(AuthActions.logoutUser),
        withLatestFrom(this._store.select(fromRoot.getCountry)),
        tap(([_, country]) => {
          const authToken = localStorage.getItem(authTokenKey);
          const thirtySecsFromNow = moment().utc().add(30, 'seconds');
          const cookieExpires = thirtySecsFromNow.format('ddd, DD MMM YYYY HH:mm:ss G\\MT');

          document.cookie = `${authTokenKey}=${authToken};path=/;expires=${cookieExpires}`;
          window.location.href = `${LOGOUT_ENDPOINT}?relayState=${country}`;
        })
      ),
    { dispatch: false }
  );

  logoutUserSuccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(AuthActions.logoutUserSuccess),
        tap(() => {
          const authToken = localStorage.getItem(authTokenKey);
          if (authToken) {
            localStorage.removeItem(authTokenKey);
          }
          this._router.navigate(['/login']);
        })
      ),
    { dispatch: false }
  );

  forbidden$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(AuthActions.forbidden),
        tap(() => {
          this._snackBarService.openSnackBar('error', [{ key: 'http-errors.generic.forbidden' }], undefined, 5000);
        })
      ),
    { dispatch: false }
  );

  notAuthorized$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(AuthActions.notAuthorized),
        tap(({ titleTranslationKey }) => {
          const messageDuration = 5000;
          if (this._getTokenWithValidDate()) {
            this._snackBarService.openSnackBar(
              'error',
              [{ key: 'http-errors.generic.not-authorized' }],
              titleTranslationKey,
              messageDuration
            );
          } else {
            this._snackBarService.openSnackBar(
              'error',
              [{ key: 'errors.session-expired' }],
              undefined,
              messageDuration
            );
            setTimeout(() => {
              this._store.dispatch(AuthActions.logoutUser());
            }, messageDuration);
          }
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store<fromRoot.State>,
    private readonly _loginService: LoginService,
    private readonly _snackBarService: SnackBarService,
    private readonly _router: Router
  ) {}

  private _getTokenWithValidDate(): AuthenticationToken {
    const authToken = localStorage.getItem(authTokenKey);
    if (!authToken) {
      return;
    }
    const decodedAuthToken: AuthenticationToken = jwtDecode(authToken);
    const expirationDateTime = moment.unix(decodedAuthToken.exp).utc();
    const currentDateTime = moment().utc();
    if (expirationDateTime.isAfter(currentDateTime)) {
      return decodedAuthToken;
    }
    localStorage.removeItem(authTokenKey);
  }
}
