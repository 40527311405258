import { sortBy } from 'lodash';
import moment from 'moment';

import { AvailableCountry } from '../const/country';
import { AVAILABLE_LANGS } from '../const/i18n';
import {
  AnalysisClassResult,
  Asset,
  AssetAnalytics,
  AssetTag,
  AvailableLangsObject,
  BacktestResponse,
  BacktestResult,
  Category,
  ClassificationResponse,
  CurrenciesResponse,
  ExAnteAnalysis,
  ExAnteAnalysisResponse,
  InstrumentAnalysisResponse,
  InstrumentAnalysisResult,
  InstrumentsResponse,
  Liquidity,
  Project,
} from '../models';
import { formatRequestDate } from '../tools/date.tools';
import {
  floatify,
  formatPercentAnalytic,
  getYield,
  isAssetCash,
  isAssetCredit,
  parseStringifyNumber,
} from '../tools/smart-risk.tools';

type AvailableLang = 'en' | 'es' | 'fr' | 'nl';

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class SrapiResponseAdapter {
  static instrumentResponseToAsset(instrumentsResponse: InstrumentsResponse, country: AvailableCountry): Asset[] {
    const assets = instrumentsResponse.instruments.map(instrument => {
      const nameColorTags = ['NameFR', 'NameEN', 'NameNL', 'NameES', 'ColorFR', 'ColorEN', 'ColorNL', 'ColorES'];
      const asset: Asset = {
        id: parseInt(instrument.id, 10),
        currency: instrument.currency,
        name: { fr: '', en: '', nl: '', es: '' },
        shortName: { fr: '', en: '', nl: '', es: '' },
        color: { fr: '', en: '', nl: '', es: '' },
        profiles: [],
        isAllowed: true,
        analyticsData: {
          returns: [],
          volatilities: [],
          yields: [],
        },
        liquidity: { fr: '', en: '', nl: '', es: '' },
      };

      instrument.tags.forEach(tag => {
        if (nameColorTags.includes(tag.name)) {
          const lowerCaseName = tag.name.toLowerCase();
          const nameLength = lowerCaseName.length;
          const assetKey = lowerCaseName.substring(0, nameLength - 2) as 'color' | 'name';
          const countryKey = lowerCaseName.substring(nameLength - 2, nameLength) as AvailableLang;

          asset[assetKey][countryKey] = assetKey === 'color' ? tag.value.replace(new RegExp(':', 'g'), ',') : tag.value;
          return;
        }
        const langs = AVAILABLE_LANGS[country].map(lang => lang.toUpperCase());
        const tagIsProfile = tag.name.match(new RegExp(`^AdditionalInfo_(\\d*)-${country}-(.*)(${langs.join('|')})$`));
        if (tagIsProfile) {
          this._setAssetProfile(asset, country, tag, tagIsProfile);
          return;
        }
        if (tag.name.includes('AssetExpectedReturnCurrency')) {
          asset.analyticsData.returns.push(this._getAnalyticData(tag, 'AssetExpectedReturnCurrency'));
          return;
        }
        if (tag.name.includes('AssetVolatility')) {
          asset.analyticsData.volatilities.push(this._getAnalyticData(tag, 'AssetVolatility'));
          return;
        }
        if (tag.name.includes('AssetDividendYieldCurrency')) {
          asset.analyticsData.yields.push(this._getAnalyticData(tag, 'AssetDividendYieldCurrency'));
          return;
        }
        if (tag.name.includes('AdditionalInfo_0-LIQUIDITY')) {
          const liquidityLang = tag.name.split('AdditionalInfo_0-LIQUIDITY')[1].toLowerCase() as AvailableLang;
          asset.liquidity[liquidityLang] = tag.value;
          return;
        }
        if (tag.name.includes('AdditionalInfo_1-SHORT-NAME')) {
          const shortNameLang = tag.name.split('AdditionalInfo_1-SHORT-NAME')[1].toLowerCase() as AvailableLang;
          asset.shortName[shortNameLang] = tag.value;
          return;
        }
      });

      return asset;
    });
    const assetsSortedById = sortBy(assets, ['id']);
    const cashAssets = assetsSortedById.filter(asset => isAssetCash(asset));
    const creditAssets = assetsSortedById.filter(asset => isAssetCredit(asset));
    const filteredAssets = assetsSortedById.filter(asset => !isAssetCash(asset) && !isAssetCredit(asset));

    return filteredAssets.concat(cashAssets, creditAssets);
  }

  static currenciesResponseToCurrencies(currenciesResponse: CurrenciesResponse): string[] {
    return currenciesResponse.userMatrices.filter(currency => !currency.includes('-HEDGED'));
  }

  static classificationResponseToCategory(classificationsResponse: ClassificationResponse): Category[] {
    const assetClassification = classificationsResponse.classifications.find(
      classification => classification.classificationId === 'AssetClass'
    );
    if (!assetClassification?.classifs) {
      return [];
    }
    const classifs = [...assetClassification.classifs];
    classifs.push(classifs.shift());

    return classifs.map((classif, index) => ({
      id: index,
      name: {
        fr: classif.tags.find(tag => tag.name === 'NameFR').value,
        en: classif.tags.find(tag => tag.name === 'NameEN').value,
        nl: classif.tags.find(tag => tag.name === 'NameNL').value,
        es: classif.tags.find(tag => tag.name === 'NameES').value,
      },
      assetIds: classif.composition.map(instrument => parseInt(instrument.instrumentId, 10)),
    }));
  }

  static exAnteAnalysisResponseToResult(
    project: Project,
    assetsList: Asset[],
    analysisResponse: ExAnteAnalysisResponse[],
    cashReturn: number
  ): ExAnteAnalysis[] {
    return analysisResponse.map(analysis => {
      const sharperatio = floatify(
        (analysis.analysisResult.portfolioResult.return - cashReturn) /
          analysis.analysisResult.portfolioResult.riskResults.Volatility
      );
      const instrumentResults = this._formatInstrumentResults(analysis.analysisResult.instrumentResults);
      return {
        allocationId: parseInt(analysis.analysisResult.portfolioId, 10),
        allocationResult: {
          risk: formatPercentAnalytic(analysis.analysisResult.portfolioResult.riskResults.Volatility, 10),
          return: formatPercentAnalytic(analysis.analysisResult.portfolioResult.return, 10),
          sharpeRatio: Math.round(sharperatio * 100) / 100,
          normalVar: formatPercentAnalytic(analysis.analysisResult.portfolioResult.riskResults.NormalVaR, 10),
          yield: getYield(project, assetsList, instrumentResults),
        },
        instrumentResults,
        liquidity: this._classificationToLiquidity(
          analysis.analysisResult.classificationResults[0].analysisClassResults
        ),
      };
    });
  }

  static backtestResponseToResult(backtestResponse: BacktestResponse[]): BacktestResult[] {
    return backtestResponse
      .filter(backtestResponseItem => backtestResponseItem.portfolioBacktestResult)
      .map(backtestResponseItem => {
        const portfolioBacktestResult = backtestResponseItem.portfolioBacktestResult;
        const maxDrawdownMeasure = portfolioBacktestResult.measures.find(measure => measure.measure === 'maxDrawdown');
        const backtestResults = {
          allocationId: parseInt(portfolioBacktestResult.portfolioId, 10),
          maxDrawDown: maxDrawdownMeasure ? formatPercentAnalytic(maxDrawdownMeasure.value, 10) : 0,
          portfolioReturns: [
            {
              value: 100,
              date: formatRequestDate(moment(portfolioBacktestResult.portfolioReturns[0].date).subtract(1, 'month')),
            },
          ],
        };
        portfolioBacktestResult.portfolioReturns.forEach((portfolioReturn, index) =>
          backtestResults.portfolioReturns.push({
            value: Math.round(backtestResults.portfolioReturns[index].value * (portfolioReturn.value + 1) * 10) / 10,
            date: formatRequestDate(moment(portfolioReturn.date)),
          })
        );
        return backtestResults;
      });
  }

  private static _formatInstrumentResults(
    instrumentsResponse: InstrumentAnalysisResponse[]
  ): InstrumentAnalysisResult[] {
    let riskDiff = 100;
    let returnDiff = 100;
    let instrumentsResult = instrumentsResponse
      .filter(instrument => !isNaN(parseInt(instrument.instrumentId, 10)))
      .map(instrument => {
        const getPrecisionMultiplier = (val: number): number => (val < 1 && val > -1 ? 10 : 1);
        const currentRisk = formatPercentAnalytic(
          instrument.riskContributions.Volatility,
          getPrecisionMultiplier(instrument.riskContributions.Volatility * 100)
        );
        const currentReturn = formatPercentAnalytic(
          instrument.returnContribution,
          getPrecisionMultiplier(instrument.returnContribution * 100)
        );
        riskDiff = floatify(riskDiff - currentRisk);
        returnDiff = floatify(returnDiff - currentReturn);
        return {
          instrumentId: parseInt(instrument.instrumentId, 10),
          weight: floatify(instrument.weight * 100),
          risk: currentRisk,
          return: currentReturn,
        };
      });

    if (riskDiff !== 0) {
      instrumentsResult = this._getBalancedInstrumentsResult(instrumentsResult, riskDiff, 'risk');
    }
    if (returnDiff !== 0) {
      instrumentsResult = this._getBalancedInstrumentsResult(instrumentsResult, returnDiff, 'return');
    }

    return instrumentsResult;
  }

  private static _getBalancedInstrumentsResult(
    instrumentsResult: InstrumentAnalysisResult[],
    instrumentsDiff: number,
    analyticType: 'return' | 'risk'
  ): InstrumentAnalysisResult[] {
    const sortedInstruments = [...instrumentsResult].sort((a, b) => b[analyticType] - a[analyticType]);
    const betweenOneAndMinusOne = (value: number): boolean => value < 1 && value > -1;
    let i = 0;
    while (instrumentsDiff !== 0 && sortedInstruments[i]) {
      if (betweenOneAndMinusOne(instrumentsDiff)) {
        // filter instruments with values between 1 and -1
        // and add or remove the same amount to each of them
        const instrumentsToUpdate = instrumentsResult.filter(
          result => result[analyticType] !== Math.round(result[analyticType])
        );
        const diff = Math.round((instrumentsDiff * 10) / instrumentsToUpdate.length) / 10;

        // eslint-disable-next-line @typescript-eslint/no-loop-func
        instrumentsToUpdate.forEach(result => {
          result[analyticType] = floatify(result[analyticType] + diff);
          instrumentsDiff = floatify(instrumentsDiff - diff);
        });
        /* add instrumentsDiff to the instrument with the biggest decimal value
         if instrumentsDiff is not equals to 0 (because of Math.round) */
        if (instrumentsDiff !== 0) {
          const instrumentToUpdate = sortedInstruments.find(
            instrument => instrument[analyticType] !== Math.round(instrument[analyticType])
          );
          instrumentToUpdate[analyticType] = floatify(instrumentToUpdate[analyticType] + instrumentsDiff);
        }
        instrumentsDiff = 0;
      } else {
        // add or remove 1 to instruments values sorted by descending order
        const diff = instrumentsDiff > 0 ? 1 : -1;
        const instrumentToUpdate = instrumentsResult.find(
          // eslint-disable-next-line @typescript-eslint/no-loop-func
          result => result.instrumentId === sortedInstruments[i].instrumentId
        );
        instrumentToUpdate[analyticType] = floatify(instrumentToUpdate[analyticType] + diff);

        instrumentsDiff = floatify(instrumentsDiff - diff);
      }
      i++;
    }

    return instrumentsResult;
  }

  private static _classificationToLiquidity(analysisClassResults: AnalysisClassResult[]): Liquidity[] {
    const liquidity = analysisClassResults.map(classResult => ({
      label: classResult.sector,
      value: floatify(classResult.classResult.classificationDetail.weight * 100),
    }));

    const absTotalLiquidity = liquidity.reduce(
      (total: number, current: Liquidity) => total + Math.abs(current.value),
      0
    );
    if (absTotalLiquidity === 100) {
      return liquidity;
    }
    return liquidity.map(liquidityItem => ({
      label: liquidityItem.label,
      value: Math.round((Math.abs(liquidityItem.value) / absTotalLiquidity) * 100),
    }));
  }

  private static _setAssetProfile(
    asset: Asset,
    country: AvailableCountry,
    tag: AssetTag,
    tagIsProfile: RegExpMatchArray
  ): void {
    const id = parseInt(tagIsProfile[1], 10);
    const existingProfile = asset.profiles.find(profile => profile.id === id);
    const tagLang = AVAILABLE_LANGS[country].find(lang => lang === tagIsProfile[3].toLowerCase());
    if (tagLang) {
      if (existingProfile) {
        existingProfile.label[tagLang] = tagIsProfile[2];
        if (tagLang === 'en') {
          existingProfile.value = tagIsProfile[2];
        }
        return;
      }
      const labelEmptyStrings: AvailableLangsObject = { en: '', fr: '', nl: '', es: '' };
      asset.profiles.push({
        id,
        value: tagLang === 'en' ? tagIsProfile[2] : '',
        label: { ...labelEmptyStrings, [tagLang]: tagIsProfile[2] },
        assetAllowed: tag.value === 'True',
      });
    }
  }

  private static _getAnalyticData(
    tag: AssetTag,
    tagName: 'AssetDividendYieldCurrency' | 'AssetExpectedReturnCurrency' | 'AssetVolatility'
  ): AssetAnalytics {
    const analyticInfos = tag.name.split(tagName)[1].split('-');
    return {
      currency: analyticInfos[0],
      hedge: !!analyticInfos[1],
      value: parseStringifyNumber(tag.value),
    };
  }
}
