import { Injectable } from '@angular/core';
import { AvailableLang } from '@app/core/const/i18n';
import { paletteAllocationsGreens } from '@app/core/const/palette';
import { BacktestResult } from '@app/core/models';
import { defaultTooltip, tooltipFormatter, tooltipPosition } from '@app/core/tools/echarts-tools';
import { TranslocoService } from '@ngneat/transloco';
import { EChartsOption, LineSeriesOption } from 'echarts';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class BacktestChartsService {
  constructor(private readonly _translocoService: TranslocoService) {}

  getBacktestOptions(
    backtestResults: BacktestResult[],
    itemParams: { benchmarkFullName: string; benchmarkColor: string },
    currentLang: AvailableLang
  ): EChartsOption {
    const tooltipLabelIcon = (seriesName: string): string => {
      const currentAllocation = backtestResults.find(
        (_, resultIndex) => seriesName === this._getAllocationName(resultIndex)
      );
      const backgroundColor = currentAllocation
        ? paletteAllocationsGreens[currentAllocation.allocationId]
        : itemParams.benchmarkColor;
      return `<span class="backtest-icon" style="background-color:${backgroundColor}"></span>`;
    };
    const data: { colors: string[]; allocName: string[] } = { colors: [], allocName: [] };
    const series: LineSeriesOption[] = backtestResults.map((backtestResult, index) => {
      let allocName = this._getAllocationName(index);
      if (index === backtestResults.length - 1) {
        allocName = itemParams.benchmarkFullName;
        data.colors.push(itemParams.benchmarkColor);
        data.allocName.push(allocName);
      } else {
        data.colors.push(paletteAllocationsGreens[index]);
        data.allocName.push(allocName);
      }
      return {
        type: 'line',
        showSymbol: false,
        name: allocName,
        itemStyle: { color: data.colors[data.colors.length - 1] },
        data: backtestResult.portfolioReturns.map(portfolioReturn => ({
          name: portfolioReturn.date,
          value: portfolioReturn.value,
        })),
      };
    });
    const xAxisData = backtestResults[0].portfolioReturns.map(portfolioReturn =>
      moment(portfolioReturn.date).locale(currentLang).format('MMM YYYY')
    );

    return {
      baseOption: {
        textStyle: {
          fontFamily: 'BnpSans-Regular',
        },
        grid: {
          left: 30,
          top: 30,
          right: 30,
          bottom: 50,
          containLabel: true,
        },
        color: data.colors,
        legend: {
          top: 'bottom',
          data: data.allocName,
          icon: 'roundRect',
          padding: 10,
          textStyle: { fontSize: 14 },
        },
        tooltip: {
          ...defaultTooltip,
          trigger: 'axis',
          axisPointer: { type: 'line', lineStyle: { type: 'dashed' } },
          formatter: (params): string => {
            const title = moment(params[0].name).locale(currentLang).format('MMMM YYYY');
            const formatterData = params.map(param => ({
              name: `${tooltipLabelIcon(param.seriesName)}${param.seriesName} : `,
              values: [`${param.value}%`],
            }));
            return tooltipFormatter([title], formatterData);
          },
          position: (point, _params, dom, _rect, size: { contentSize: number[] }): Array<number | string> => {
            return tooltipPosition(point, dom as HTMLDivElement, size, 20);
          },
        },
        xAxis: [
          {
            type: 'category',
            splitLine: { show: false },
            axisLine: { show: false },
            axisTick: { show: true },
            axisLabel: {
              show: true,
              rotate: 45,
              margin: 10,
            },
            data: xAxisData,
            boundaryGap: false,
          },
        ],
        yAxis: [
          {
            type: 'value',
            nameTextStyle: { fontSize: 14 },
            axisTick: { show: false },
            axisLine: { show: false },
            splitLine: { show: true, lineStyle: { type: 'dashed' } },
            axisLabel: { show: true, formatter: '{value}' },
            scale: true,
            boundaryGap: ['5%', '5%'],
          },
        ],
        series,
      },
      media: [
        {
          query: {
            minWidth: 925,
          },
          option: {
            xAxis: [
              {
                axisLabel: {
                  interval: xAxisData.length >= 30 ? Math.floor(xAxisData.length / 30) : undefined,
                },
              },
            ],
          },
        },
        {
          query: {
            maxWidth: 924,
          },
          option: {
            xAxis: [
              {
                axisLabel: {
                  interval: xAxisData.length >= 20 ? Math.floor(xAxisData.length / 20) : undefined,
                },
              },
            ],
          },
        },
      ],
    };
  }

  private _getAllocationName(allocIndex: number): string {
    return `${this._translocoService.translate('global.allocation').toUpperCase()} ${allocIndex + 1}`;
  }
}
