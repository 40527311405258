export const PPT_EXPORT_TAGS = {
  date: '[v_(0)_Date]',
  financialProfile: '[v_(0)_FinancialProfile]',
  riskProfile: '[v_(0)_RiskProfile]',
  currency: '[v_(0)_AllocCurrency]',
  hedging: '[v_(0)_Hedging]',
  forecasts: '[i_(0)_Forecasts]',
  comparison: {
    compareWeights: '[i_(0)_CompareWeights]',
    comparePerformance: '[i_(0)_ComparePerformance]',
    compareRisk: '[i_(0)_CompareVolatility]',
    allocsPerformance: '[i_(0)_AllocsProfitability]',
    allocsCurrentYield: '[i_(0)_AllocsCurrentYield]',
    allocsRisk: '[i_(0)_AllocsVolatility]',
    allocsVar: '[i_(0)_AllocsVar]',
    liquidity: '[i_(0)_Liquidity]',
  },
  allocations: Array.from({ length: 3 }).map((_, i) => ({
    allocNum: `[v_(${i})_AllocNum]`,
    allocContrib: `[i_(${i})_AllocContrib]`,
    allocContribZoom: `[i_(${i})_AllocContribZoom]`,
    allocPerformance: `[v_(${i})_AllocProfitability]`,
    allocCurrentYield: `[v_(${i})_AllocCurrentYield]`,
    allocRisk: `[v_(${i})_AllocRisk]`,
    allocVar: `[v_(${i})_AllocVar]`,
    sharpeRatio: `[v_(${i})_SharpeRatio]`,
    allocLiquidity: `[i_(${i})_AllocLiquidity]`,
  })),
  backtest: {
    backtestPeriod: '[v_(0)_BacktestPeriod]',
    startDate: '[v_(0)_StartDate]',
    endDate: '[v_(0)_EndDate]',
    assetReference: '[v_(0)_AssetReference]',
    allocsMaxDrawdown: '[i_(0)_AllocsMaxDrawdawn]',
    allocsBacktest: '[i_(0)_AllocsBacktest]',
  },
};
