import { Injectable } from '@angular/core';
import { AvailableLang } from '@app/core/const/i18n';
import { palette, paletteAllocationsGreens } from '@app/core/const/palette';
import { SORTED_LIQUIDITIES } from '@app/core/const/smart-risk-config';
import { Allocation, Asset, AvailableLiquidity, InstrumentAnalysisResult, Liquidity } from '@app/core/models';
import { EchartParamsData, OtherData } from '@app/core/models/echarts-type';
import { defaultTooltip, hexToRgba, rgbToHex, tooltipFormatter } from '@app/core/tools/echarts-tools';
import { isAssetCash, isAssetCredit } from '@app/core/tools/smart-risk.tools';
import { TranslocoService } from '@ngneat/transloco';
import { BarSeriesOption, EChartsOption, PieSeriesOption } from 'echarts';
import { OptionDataItemObject, OrdinalRawValue, TextCommonOption, ZRColor } from 'echarts/types/src/util/types';

type AnalyticsData = Array<Array<{ name: string; value: number }>>;

@Injectable({
  providedIn: 'root',
})
export class ExanteAnalysisChartsService {
  private readonly _contributionSeriesIds = { other: 1, cash: 2, credit: 3 };

  constructor(private readonly _translocoService: TranslocoService) {}

  getContributionOptions(allocation: Allocation, assetsList: Asset[], currentLang: AvailableLang): EChartsOption {
    const otherPositive: OtherData = { weight: [], return: [], risk: [] };
    const otherNegative: OtherData = { weight: [], return: [], risk: [] };
    const maxYAxis = this._getContributionMaxYAxis(allocation.exAnteAnalysis.instrumentResults);

    const isInOtherPositive = (analyticsValue: number): boolean => {
      return analyticsValue > 0 && (analyticsValue * 100) / maxYAxis < 3;
    };
    const isInOtherNegative = (analyticsValue: number): boolean => {
      return analyticsValue < 0 && (analyticsValue * 100) / maxYAxis > -3;
    };
    const mustDisplayOther = (other: OtherData): boolean => {
      return other.weight.length > 1 || other.return.length > 1 || other.risk.length > 1;
    };
    const otherToDisplay = (other: OtherData): OtherData => {
      const otherData: OtherData = { ...other };
      Object.keys(otherData).forEach((analyticsKey: 'return' | 'risk' | 'weight') => {
        if (otherData[analyticsKey].length === 1) {
          otherData[analyticsKey] = [];
        }
      });
      return otherData;
    };
    const getNotInOtherAnalyticsValue = (
      analyticsValue: number,
      analyticsKey: 'return' | 'risk' | 'weight'
    ): number => {
      return (isInOtherPositive(analyticsValue) && otherPositive[analyticsKey].length > 1) ||
        (isInOtherNegative(analyticsValue) && otherNegative[analyticsKey].length > 1)
        ? null
        : analyticsValue;
    };

    allocation.exAnteAnalysis.instrumentResults.forEach(instrumentResult => {
      const currentAsset = assetsList.find(asset => asset.id === instrumentResult.instrumentId);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { instrumentId, ...instrumentAnalytics } = instrumentResult;

      // set other positive values
      Object.keys(instrumentAnalytics).forEach((analyticsKey: 'return' | 'risk' | 'weight') => {
        if (isInOtherPositive(instrumentAnalytics[analyticsKey])) {
          otherPositive[analyticsKey].push({
            name: currentAsset.name[currentLang],
            value: instrumentAnalytics[analyticsKey],
          });
        }
      });

      // set other negative values
      Object.keys(instrumentAnalytics).forEach((analyticsKey: 'return' | 'risk' | 'weight') => {
        if (isInOtherNegative(instrumentAnalytics[analyticsKey])) {
          otherNegative[analyticsKey].push({
            name: currentAsset.name[currentLang],
            value: instrumentAnalytics[analyticsKey],
          });
        }
      });
    });

    const notInOtherInstrumentValues = allocation.exAnteAnalysis.instrumentResults.map(instrumentResult => ({
      ...instrumentResult,
      weight: getNotInOtherAnalyticsValue(instrumentResult.weight, 'weight'),
      return: getNotInOtherAnalyticsValue(instrumentResult.return, 'return'),
      risk: getNotInOtherAnalyticsValue(instrumentResult.risk, 'risk'),
    }));

    const series = this._getContributionSeries(notInOtherInstrumentValues, assetsList, currentLang, maxYAxis);

    // set others series if they contain more than one instrument
    if (mustDisplayOther(otherPositive)) {
      const otherPositiveToDisplay = otherToDisplay(otherPositive);
      series.unshift(
        this._buildContributionSerie(
          this._contributionSeriesIds.other,
          `${this._translocoService.translate('analysisResults.contribution.others')} (+)`,
          palette.grey[400],
          this._getContribOtherValues(otherPositiveToDisplay),
          maxYAxis,
          Object.values(otherPositiveToDisplay)
        )
      );
    }
    if (mustDisplayOther(otherNegative)) {
      const otherNegativeToDisplay = otherToDisplay(otherNegative);
      series.unshift(
        this._buildContributionSerie(
          -this._contributionSeriesIds.other,
          `${this._translocoService.translate('analysisResults.contribution.others')} (-)`,
          palette.grey[400],
          this._getContribOtherValues(otherNegativeToDisplay),
          maxYAxis,
          Object.values(otherNegativeToDisplay)
        )
      );
    }

    return this._getContribBaseOptions(
      this._sortSeriesByIds(series),
      assetsList,
      currentLang,
      this._translocoService.translate('analysisResults.contribution.analytics-labels'),
      maxYAxis
    );
  }

  getContributionZoomOptions(
    allocations: Allocation[],
    assetsList: Asset[],
    currentLang: AvailableLang
  ): EChartsOption[] {
    return allocations.map(allocation => {
      const maxYAxis = this._getContributionMaxYAxis(allocation.exAnteAnalysis.instrumentResults);
      const isSmallValue = (value: number): boolean =>
        (value > 0 && (value * 100) / maxYAxis < 3) || (value < 0 && (value * 100) / maxYAxis > -3);

      const instrumentsWithSmallValues = allocation.exAnteAnalysis.instrumentResults
        .map(instrumentResult => {
          return {
            instrumentId: instrumentResult.instrumentId,
            weight: isSmallValue(instrumentResult.weight) ? instrumentResult.weight : undefined,
            return: isSmallValue(instrumentResult.return) ? instrumentResult.return : undefined,
            risk: isSmallValue(instrumentResult.risk) ? instrumentResult.risk : undefined,
          };
        })
        .filter(instrumentResult => instrumentResult.weight || instrumentResult.return || instrumentResult.risk);

      const smallValuesMaxYAxis = this._getContributionMaxYAxis(instrumentsWithSmallValues);

      const series = this._getContributionSeries(
        instrumentsWithSmallValues,
        assetsList,
        currentLang,
        smallValuesMaxYAxis
      );

      const contribOption = this._getContribBaseOptions(
        this._sortSeriesByIds(series),
        assetsList,
        currentLang,
        this._translocoService.translate('analysisResults.contribution.analytics-labels'),
        smallValuesMaxYAxis
      );
      contribOption.media[3].option = this._getContribMediaOption(series, assetsList, currentLang, 14, 16);

      return contribOption;
    });
  }

  getComparisonContributionOptions(
    allocationsContributionOptions: EChartsOption[],
    assetsList: Asset[],
    currentLang: AvailableLang
  ): EChartsOption[] {
    // this any is lazy bit I could not find a corresponding type for EChartOption.BasicComponents.CartesianAxis.DataObject
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const xAxisData: any[] = [];
    const comparisonSeries: BarSeriesOption[][] = [[], [], []];
    const maxYAxisArray: number[][] = [[], [], []];

    comparisonSeries.forEach((series, index) => {
      allocationsContributionOptions.forEach((allocationOptions, allocIndex) => {
        if (index === 0) {
          xAxisData.push({
            value: this._getAllocationName(allocIndex),
            textStyle: { color: paletteAllocationsGreens[allocIndex], fontWeight: 'bold' },
          });
        }
        const currentAllocationSeries = allocationOptions.baseOption.series;
        (currentAllocationSeries as BarSeriesOption[]).forEach(
          (allocationSeriesItem: BarSeriesOption, seriesItemIndex) => {
            const currentData = allocationSeriesItem.data[index] as OptionDataItemObject<BarSeriesOption>;
            const existingSerie = series.find(seriesItem => seriesItem.id === allocationSeriesItem.id);
            if ((currentData.value as number) > 0) {
              if (maxYAxisArray[index][allocIndex]) {
                maxYAxisArray[index][allocIndex] += currentData.value as number;
              } else {
                maxYAxisArray[index].push(currentData.value as number);
              }
            }

            if (existingSerie) {
              (existingSerie.data as Array<OptionDataItemObject<BarSeriesOption>>)[allocIndex] = currentData;
              return;
            }
            const data = Array.from({ length: allocationsContributionOptions.length }, () => null);
            data[allocIndex] = currentData;
            series.push({ ...allocationSeriesItem, data });
            series[seriesItemIndex].barCategoryGap = allocationsContributionOptions.length === 2 ? '20%' : '10%';
          }
        );
      });
    });

    return comparisonSeries.map((series, index) => {
      const maxYAxis = Math.max(...maxYAxisArray[index]);
      return this._getContribBaseOptions(
        this._sortSeriesByIds(series),
        assetsList,
        currentLang,
        xAxisData,
        maxYAxis * 1.02
      );
    });
  }

  getAnalyticsOptions(
    analyticsValues: { displayedAsNegative: boolean; values: number[] },
    maxDrawDownsParams?: {
      benchmarkFullName: string;
      benchmarkLabel: string;
      benchmarkColor: string;
      allocPrefix: string;
    }
  ): EChartsOption {
    let gridRightDistance = 40;
    let labelMargin = 50;
    let xAxisMin = 0;
    let xAxisMax = 0;
    const displayedValuePrefix = analyticsValues.displayedAsNegative ? '-' : '';
    const analyticsData = analyticsValues.values.map((value, index) => {
      const allocPrefix = maxDrawDownsParams ? maxDrawDownsParams.allocPrefix : '';
      const currentItemParam = {
        name: allocPrefix + (index + 1).toString(),
        tooltipText: `${this._getAllocationName(index)} : ${displayedValuePrefix + value}%`,
        color: paletteAllocationsGreens[index],
      };
      if (maxDrawDownsParams && index === analyticsValues.values.length - 1) {
        currentItemParam.name = maxDrawDownsParams.benchmarkLabel;
        currentItemParam.tooltipText = `${maxDrawDownsParams.benchmarkFullName} : ${displayedValuePrefix + value}%`;
        currentItemParam.color = maxDrawDownsParams.benchmarkColor;
      }
      xAxisMin = value < xAxisMin ? value * 1.05 : xAxisMin;
      xAxisMax = value > xAxisMax ? value * 1.05 : xAxisMax;
      if (value > 10) {
        gridRightDistance = 60;
      }
      if (value < -10) {
        labelMargin = 60;
      }
      return {
        value,
        name: currentItemParam.name,
        tooltip: {
          ...defaultTooltip,
          formatter: currentItemParam.tooltipText,
        },
        label: { position: value < 0 ? 'left' : 'right' },
        itemStyle: { color: currentItemParam.color },
      };
    });
    analyticsData.reverse();
    return {
      tooltip: {
        trigger: 'item',
      },
      grid: {
        left: 10,
        right: gridRightDistance,
        top: 15,
        bottom: -10,
        containLabel: true,
      },
      xAxis: {
        type: 'value',
        show: false,
        min: xAxisMin,
        max: xAxisMax,
      },
      yAxis: {
        type: 'category',
        data: analyticsData.map(analyticDataItem => analyticDataItem.name),
        axisLine: { show: false },
        axisTick: { show: false },
        axisLabel: {
          show: true,
          margin: analyticsValues.values.find(value => value < 0) ? labelMargin : 10,
          color: '#000',
          fontWeight: 'bold',
          fontSize: 14,
        },
        z: 2,
      },
      series: [
        {
          type: 'bar',
          barMaxWidth: 35,
          label: {
            show: true,
            distance: maxDrawDownsParams ? 10 : 5,
            formatter: `${displayedValuePrefix}{c}%`,
            color: palette.grey[600],
            fontWeight: 'bold',
            fontSize: 14,
          },
          data: analyticsData,
        } as BarSeriesOption,
      ],
    };
  }

  getLiquidityOptions(
    liquidity: Array<{ label: string; value: number }>,
    availableLiquidities: AvailableLiquidity[],
    currentLang: AvailableLang
  ): EChartsOption {
    const data: BarSeriesOption[] = liquidity
      .filter(liquidityItem => liquidityItem.value !== 0)
      .map(liquidityItem => ({
        name: availableLiquidities.find(availableLiquidity => availableLiquidity.name.en === liquidityItem.label).name[
          currentLang
        ],
        value: liquidityItem.value,
        itemStyle: {
          borderWidth: 3,
          borderColor: '#fff',
          color: availableLiquidities.find(availableLiquidity => availableLiquidity.name.en === liquidityItem.label)
            .color,
        },
        label: {
          color: availableLiquidities.find(availableLiquidity => availableLiquidity.name.en === liquidityItem.label)
            .color,
        },
      }));
    const sortedLiquidities = this._sortLiquiditiesByNames(data, currentLang);

    return {
      baseOption: {
        textStyle: {
          fontFamily: 'BnpSans-Regular',
        },
        legend: {
          top: 'middle',
          right: '10%',
          orient: 'vertical',
          itemGap: 15,
          data: sortedLiquidities.map(dataItem => String(dataItem.name)),
        },
        series: [
          {
            type: 'pie',
            avoidLabelOverlap: true,
            label: {
              show: false,
              position: 'center',
              verticalAlign: 'middle',
              borderRadius: 50,
              backgroundColor: palette.grey[50],
            },
            emphasis: {
              label: {
                show: true,
              },
            },
            labelLine: { show: false },
            data: sortedLiquidities,
          } as PieSeriesOption,
        ],
      },
      media: [
        {
          query: {
            minWidth: 444,
          },
          option: {
            legend: {
              right: '8%',
              textStyle: { fontSize: 12, padding: [0, 0, 0, 5] },
            },
            series: [
              {
                hoverOffset: 10,
                radius: ['40%', '80%'],
                center: ['33%', '50%'],
                label: {
                  padding: 20,
                  width: 60,
                  height: 55,
                  fontSize: 22,
                  formatter: `{b|${this._translocoService.translate(
                    'analysisResults.allocation-analytics.weight'
                  )}}\n{c}%`,
                  rich: {
                    b: { fontSize: 14, padding: [10, 0, 0, 0] },
                  },
                },
              },
            ],
          },
        },
        {
          query: {
            minWidth: 331,
            maxWidth: 443,
          },
          option: {
            legend: {
              right: '4%',
              textStyle: { fontSize: 12, padding: [0, 0, 0, 5] },
            },
            series: [
              {
                hoverOffset: 10,
                radius: ['32%', '65%'],
                center: ['32%', '50%'],
                label: {
                  padding: 18,
                  width: 45,
                  height: 45,
                  fontSize: 22,
                  formatter: `{b|${this._translocoService.translate(
                    'analysisResults.allocation-analytics.weight'
                  )}}\n{c}%`,
                  rich: {
                    b: { fontSize: 12, padding: [8, 0, 0, 0] },
                  },
                },
              },
            ],
          },
        },
        {
          query: {
            minWidth: 294,
            maxWidth: 330,
          },
          option: {
            legend: {
              right: 5,
              textStyle: { fontSize: 11, padding: [0, 0, 0, 5] },
            },
            series: [
              {
                hoverOffset: 7,
                radius: ['35%', '70%'],
                center: ['30%', '50%'],
                label: {
                  padding: 20,
                  width: 38,
                  height: 38,
                  fontSize: 18,
                  formatter: `{b|${this._translocoService.translate(
                    'analysisResults.allocation-analytics.weight'
                  )}}\n{c}%`,
                  rich: {
                    b: { fontSize: 11, padding: [6, 0, 0, 0] },
                  },
                },
              },
            ],
          },
        },
        {
          query: {
            maxWidth: 293,
          },
          option: {
            legend: {
              right: 0,
              textStyle: { fontSize: 11, padding: 0 },
            },
            series: [
              {
                hoverOffset: 4,
                radius: ['28%', '53%'],
                center: ['26%', '50%'],
                label: {
                  padding: [20, 16, 12, 16],
                  width: 25,
                  height: 25,
                  fontSize: 17,
                  formatter: '{c}%',
                  rich: {},
                },
              },
            ],
          },
        },
      ],
    };
  }

  getExportLiquidityOptions(
    liquidity: Liquidity[],
    availableLiquidities: AvailableLiquidity[],
    currentLang: AvailableLang
  ): EChartsOption {
    const { baseOption, media } = this.getLiquidityOptions(liquidity, availableLiquidities, currentLang);
    baseOption.legend = { ...baseOption.legend, ...(media[2].option.legend as object) };
    return {
      ...baseOption,
      series: (baseOption.series as PieSeriesOption[]).map((pieSeries: PieSeriesOption) => ({
        ...pieSeries,
        radius: ['35%', '70%'],
        center: ['35%', '50%'],
        label: {
          show: true,
          position: 'outside',
          formatter: '{c}%',
          fontSize: 18,
        },
        labelLine: { show: false, length: 12, length2: 5 },
      })),
    };
  }

  getLiquiditiesOptions(
    liquidities: Liquidity[][],
    availableLiquidities: AvailableLiquidity[],
    currentLang: AvailableLang
  ): EChartsOption {
    const series: BarSeriesOption[] = [];
    const yAxisData: string[] = [];
    const getSeriesDataArray = (data: object): BarSeriesOption[] => data as BarSeriesOption[];
    const compare = (
      a: BarSeriesOption | OptionDataItemObject<BarSeriesOption>,
      b: BarSeriesOption | OptionDataItemObject<BarSeriesOption>
    ): number => {
      const nameA = a.name;
      const nameB = b.name;
      if (nameA < nameB) {
        return 1;
      }
      if (nameA > nameB) {
        return -1;
      }
      return 0;
    };

    liquidities.forEach((liquidity, index) => {
      const allocationName = this._getAllocationName(index);
      yAxisData.push(allocationName);
      liquidity.forEach(liquidityItem => {
        const currentLiquidity = availableLiquidities.find(
          availableLiquidity => availableLiquidity.name.en === liquidityItem.label
        );
        if (liquidityItem.value !== 0) {
          const data = {
            name: allocationName,
            value: liquidityItem.value,
            itemStyle: {
              color: currentLiquidity.color,
            },
          };
          const existingSeriesItem = series.find(seriesItem => seriesItem.name === currentLiquidity.name[currentLang]);
          if (existingSeriesItem) {
            getSeriesDataArray(existingSeriesItem.data).push(data);
          } else {
            series.push({
              name: currentLiquidity.name[currentLang],
              type: 'bar',
              barMaxWidth: 30,
              barCategoryGap: '70%',
              stack: 'liquidities',
              emphasis: {
                itemStyle: {
                  color: currentLiquidity.color,
                  shadowColor: hexToRgba(currentLiquidity.color, 0.7),
                  shadowOffsetY: 3,
                  shadowBlur: 7,
                },
                label: {
                  show: true,
                  position: 'top',
                  formatter: '{c}%',
                  color: 'black',
                  fontWeight: 'bold',
                  fontSize: 14,
                },
              },
              data: [data],
            });
          }
        }
      });
    });
    series.forEach(seriesItem => {
      liquidities.forEach((_liquidity, index) => {
        const allocationName = this._getAllocationName(index);
        if (!getSeriesDataArray(seriesItem.data).find(dataItem => dataItem.name === allocationName)) {
          getSeriesDataArray(seriesItem.data).push({
            name: allocationName,
            value: null,
            itemStyle: {
              color: availableLiquidities.find(
                availableLiquidity => availableLiquidity.name[currentLang] === seriesItem.name
              ).color,
            },
          } as OptionDataItemObject<BarSeriesOption>);
        }
      });
      getSeriesDataArray(seriesItem.data).sort(compare);
    });
    const sortedSeries: BarSeriesOption[] = this._sortLiquiditiesByNames(series, currentLang);

    return {
      baseOption: {
        textStyle: {
          fontFamily: 'BnpSans-Regular',
        },
        grid: {
          left: 10,
          top: 5,
          right: 10,
          height: '85%',
          containLabel: true,
        },
        color: sortedSeries.map(seriesItem => getSeriesDataArray(seriesItem.data)[0].itemStyle.color as ZRColor),
        legend: {
          top: 'bottom',
          itemWidth: 20,
          data: sortedSeries.map(seriesItem => String(seriesItem.name)),
        },
        tooltip: { show: false },
        yAxis: {
          type: 'category',
          show: true,
          axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: { show: true, margin: 20, padding: [3, 0, 0, 0], fontWeight: 'bold' },
          // @FIXME
          // boundaryGap: ['0%', '0%'],
        },
        xAxis: {
          type: 'value',
          position: 'top',
          show: false,
        },
        series: sortedSeries,
      },
      media: [
        {
          query: {
            minWidth: 326,
          },
          option: { legend: { textStyle: { fontSize: 12 } }, yAxis: { data: yAxisData.reverse() } },
        },
        {
          query: {
            maxWidth: 325,
          },
          option: {
            legend: { textStyle: { fontSize: 11 } },
            yAxis: { data: yAxisData.map((_dataItem, index) => `ALLOC. ${index + 1}`).reverse() },
          },
        },
      ],
    };
  }

  getExportLiquiditiesOptions(
    liquidities: Liquidity[][],
    availableLiquidities: AvailableLiquidity[],
    currentLang: AvailableLang
  ): EChartsOption {
    const { baseOption, media } = this.getLiquiditiesOptions(liquidities, availableLiquidities, currentLang);
    baseOption.legend = { ...baseOption.legend, ...(media[0].option.legend as object) };
    baseOption.yAxis = { ...baseOption.yAxis, ...(media[0].option.yAxis as object) };
    return {
      ...baseOption,
      series: (baseOption.series as BarSeriesOption[]).map((seriesItem: BarSeriesOption) => ({
        ...seriesItem,
        label: {
          ...seriesItem.label,
          show: true,
          position: 'top',
          formatter: '{c}%',
        },
      })),
    };
  }

  private _sortSeriesByIds(series: BarSeriesOption[]): BarSeriesOption[] {
    const compareIds = (a: BarSeriesOption, b: BarSeriesOption): number => {
      const idA = parseInt(String(a.id), 10);
      const idB = parseInt(String(b.id), 10);
      if (idA > idB) {
        return 1;
      }
      if (idA < idB) {
        return -1;
      }
      return 0;
    };

    return [...series].sort(compareIds);
  }

  private _getContributionSeries(
    instrumentsResults: InstrumentAnalysisResult[],
    assetsList: Asset[],
    currentLang: AvailableLang,
    maxYAxis: number
  ): BarSeriesOption[] {
    return instrumentsResults.map(instrumentResult => {
      const currentAsset = assetsList.find(asset => asset.id === instrumentResult.instrumentId);
      if (currentAsset) {
        let seriesId = currentAsset.id + Object.keys(this._contributionSeriesIds).length + 1;
        if (isAssetCash(currentAsset)) {
          seriesId = this._contributionSeriesIds.cash;
        }
        if (isAssetCredit(currentAsset)) {
          seriesId = this._contributionSeriesIds.credit;
        }
        return this._buildContributionSerie(
          seriesId,
          currentAsset.name[currentLang],
          currentAsset.color[currentLang],
          [instrumentResult.weight, instrumentResult.return, instrumentResult.risk],
          maxYAxis
        );
      }
    });
  }

  private _buildContributionSerie(
    id: number,
    name: string,
    color: string,
    values: number[],
    maxYAxis: number,
    otherData?: AnalyticsData
  ): BarSeriesOption {
    return {
      id: id.toString(),
      name,
      type: 'bar',
      stack: 'contribution',
      barMinHeight: 8,
      barCategoryGap: '10%',
      data: values.map((value, index) => ({
        name,
        value: value !== 0 ? value : null,
        label: {
          show: (value * 100) / maxYAxis > 3 || (value * 100) / maxYAxis < -3,
          position: 'inside',
          color: palette.white[400],
        },
        tooltip: {
          ...defaultTooltip,
          formatter: otherData
            ? tooltipFormatter(
                [name],
                otherData[index].map(item => ({ ...item, values: [`${item.value}%`] }))
              )
            : '{b} : {c}%',
        },
        itemStyle: { color: otherData ? color : rgbToHex(color), borderColor: '#fff', borderWidth: 2 },
      })),
    };
  }

  private _getContribMediaOption(
    series: BarSeriesOption[],
    assetsList: Asset[],
    currentLang: AvailableLang,
    fontSize: number,
    maxLength: number
  ): any {
    return {
      xAxis: {
        axisLabel: { fontSize },
      },
      series: series.map(() => ({
        label: {
          formatter: (params: EchartParamsData): string => {
            const nameType: 'name' | 'shortName' = maxLength > 20 ? 'name' : 'shortName';
            const currentAsset = assetsList.find(asset => asset.name[currentLang] === params.name);
            const label = currentAsset ? currentAsset[nameType][currentLang] : params.name;
            const assetName = label.length > maxLength ? `${label.substr(0, maxLength - 1)}.` : label;
            return `${assetName} ${params.value}%`;
          },
        },
      })),
    };
  }

  private _getContribOtherValues(otherData: OtherData): number[] {
    const getTotalValue = (dataArray: Array<{ name: string; value: number }>): number => {
      return dataArray.length > 0
        ? Math.round(
            dataArray.reduce((totalValue, item) => {
              return totalValue + item.value;
            }, 0) * 10
          ) / 10
        : null;
    };
    return [getTotalValue(otherData.weight), getTotalValue(otherData.return), getTotalValue(otherData.risk)];
  }

  private _getContribBaseOptions(
    series: BarSeriesOption[],
    assetsList: Asset[],
    currentLang: AvailableLang,
    xAxisData: Array<{ value: OrdinalRawValue; textStyle?: TextCommonOption }> | string[],
    maxYAxis: number
  ): EChartsOption {
    const seriesDataValues = series.map(seriesItem =>
      (seriesItem.data as Array<{ value: number }>).filter(dataItem => !!dataItem).map(dataItem => dataItem.value)
    );
    const totalNegative = seriesDataValues.reduce(
      (total, seriesItemValue) => {
        total.weight = seriesItemValue[0] < 0 ? total.weight + seriesItemValue[0] : total.weight;
        total.return = seriesItemValue[1] < 0 ? total.return + seriesItemValue[1] : total.return;
        total.risk = seriesItemValue[2] < 0 ? total.risk + seriesItemValue[2] : total.risk;
        return total;
      },
      { weight: 0, return: 0, risk: 0 }
    );

    return {
      baseOption: {
        textStyle: {
          fontFamily: 'BnpSans-Regular',
        },
        grid: {
          left: 10,
          top: 50,
          right: 10,
          bottom: 20,
          height: '90%',
        },
        yAxis: {
          type: 'value',
          show: false,
          min: Math.min(...Object.values(totalNegative)) * 1.02,
          max: maxYAxis,
        },
        xAxis: {
          type: 'category',
          position: 'top',
          axisTick: { show: false },
          axisLabel: { verticalAlign: 'top', margin: 20 },
          data: xAxisData,
          z: 2,
        },
        tooltip: {
          trigger: 'item',
        },
        series,
      },
      media: [
        {
          query: {
            minWidth: 727,
          },
          option: this._getContribMediaOption(series, assetsList, currentLang, 14, 25),
        },
        {
          query: {
            minWidth: 600,
            maxWidth: 726,
          },
          option: this._getContribMediaOption(series, assetsList, currentLang, 14, 20),
        },
        {
          query: {
            minWidth: 520,
            maxWidth: 599,
          },
          option: this._getContribMediaOption(series, assetsList, currentLang, 14, 16),
        },
        {
          query: {
            maxWidth: 519,
          },
          option: this._getContribMediaOption(series, assetsList, currentLang, 11, 12),
        },
      ],
    };
  }

  private _getContributionMaxYAxis(instrumentsResults: InstrumentAnalysisResult[]): number {
    const totalPositive = instrumentsResults.reduce(
      (total, instrumentResult) => {
        total.weight = instrumentResult.weight > 0 ? total.weight + instrumentResult.weight : total.weight;
        total.return = instrumentResult.return > 0 ? total.return + instrumentResult.return : total.return;
        total.risk = instrumentResult.risk > 0 ? total.risk + instrumentResult.risk : total.risk;
        return total;
      },
      { weight: 0, return: 0, risk: 0 }
    );
    return Math.max(...Object.values(totalPositive)) * 1.02;
  }

  private _getAllocationName(allocIndex: number): string {
    return `${this._translocoService.translate('global.allocation').toUpperCase()} ${allocIndex + 1}`;
  }

  private _sortLiquiditiesByNames(
    liquiditiesData: Array<OptionDataItemObject<BarSeriesOption>> | BarSeriesOption[],
    currentLang: AvailableLang
  ): BarSeriesOption[] {
    const compareNames = (
      a: BarSeriesOption | OptionDataItemObject<BarSeriesOption>,
      b: BarSeriesOption | OptionDataItemObject<BarSeriesOption>
    ): number => {
      const liquidityA = SORTED_LIQUIDITIES.find(sortedLiquidity => sortedLiquidity.name[currentLang] === a.name);
      const liquidityB = SORTED_LIQUIDITIES.find(sortedLiquidity => sortedLiquidity.name[currentLang] === b.name);
      const indexA = liquidityA ? liquidityA.index : -1;
      const indexB = liquidityB ? liquidityB.index : -1;
      if (indexA > indexB) {
        return 1;
      }
      if (indexA < indexB) {
        return -1;
      }
      return 0;
    };

    return [...liquiditiesData].sort(compareNames);
  }
}
