import { AvailableCountry } from '@app/core/const/country';
import { AvailableLang } from '@app/core/const/i18n';
import { routerNavigationAction } from '@ngrx/router-store';
import { Action, createReducer, on } from '@ngrx/store';

import { AppActions } from '../actions';

export type AppState = {
  disclaimerToDisplay: string;
  currentLang: AvailableLang;
  country: AvailableCountry;
  updateEntitiesAt: string;
  exportAnalysisRunning: boolean;
  isScreenWide: boolean;
};

const initialState: AppState = {
  disclaimerToDisplay: '',
  currentLang: undefined,
  country: undefined,
  updateEntitiesAt: undefined,
  exportAnalysisRunning: false,
  isScreenWide: undefined,
};

const appReducer = createReducer(
  initialState,
  on(routerNavigationAction, (state, { payload }) => ({
    ...state,
    disclaimerToDisplay: payload.event.url.substring(1),
  })),
  on(AppActions.updateDisclaimer, (state, { disclaimerToDisplay }) => ({ ...state, disclaimerToDisplay })),
  on(AppActions.updateLanguage, (state, { currentLang }) => ({ ...state, currentLang })),
  on(AppActions.setCountry, (state, { country }) => ({ ...state, country })),
  on(AppActions.setUpdateEntitiesAt, (state, { updateEntitiesAt }) => ({ ...state, updateEntitiesAt })),
  on(AppActions.exportAnalysisResults, state => ({ ...state, exportAnalysisRunning: true })),
  on(AppActions.exportAnalysisResultsSuccess, AppActions.exportAnalysisResultsFailure, state => ({
    ...state,
    exportAnalysisRunning: false,
  })),
  on(AppActions.isScreenWideChanged, (state, { isScreenWide }) => ({ ...state, isScreenWide }))
);

export function reducer(state: AppState | undefined, action: Action): AppState {
  return appReducer(state, action);
}
