import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TooltipItem, TOOLTIPS } from '@app/core/const/tooltips';
import * as fromRoot from '@app/store/reducers';
import { TranslocoService } from '@ngneat/transloco';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

@Component({
  selector: 'am-tooltip-modal',
  templateUrl: './tooltip-modal.component.html',
  styleUrls: ['./tooltip-modal.component.scss'],
})
export class TooltipModalComponent implements OnInit {
  tooltips: TooltipItem[];
  title: string;

  constructor(
    private readonly _store: Store<fromRoot.State>,
    private readonly _translocoService: TranslocoService,
    @Inject(MAT_DIALOG_DATA) public tooltipId: string
  ) {}

  ngOnInit(): void {
    this._store.pipe(select(fromRoot.getCurrentLang), take(1)).subscribe(currentLang => {
      const tooltipsData = TOOLTIPS.find(tooltips => tooltips.displayedOn === this.tooltipId);
      this.tooltips = tooltipsData
        ? tooltipsData[currentLang].tooltips
        : [{ word: '', definition: this._translocoService.translate('shared.tooltip-modal.no-definition') }];
      this.title = tooltipsData
        ? tooltipsData[currentLang].title
        : this._translocoService.translate('shared.tooltip-modal.error-title');
    });
  }
}
