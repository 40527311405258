import { Injectable } from '@angular/core';
import { SRAPI_REGEXP } from '@app/core/const/srapi-errors';
import { ErrorMessageData, ErrorResponseMessages, TranslationObject } from '@app/core/models';
import {
  BACKTEST_ENDPOINT,
  CLASSIFICATIONS_ENDPOINT,
  EX_ANTE_ANALYSIS_ENDPOINT,
  FX_RATES_ENDPOINT,
  INSTRUMENTS_ENDPOINT,
  INSTRUMENTS_VALUES_ENDPOINT,
  USER_MATRICES_ENDPOINT,
} from '@app/core/services/smart-risk.service';

@Injectable({
  providedIn: 'root',
})
export class SrapiErrorsHandler {
  private readonly _errorTitlesKeys = [
    { url: INSTRUMENTS_ENDPOINT, key: 'http-errors.smart-risk.title.assets' },
    { url: INSTRUMENTS_VALUES_ENDPOINT, key: 'http-errors.smart-risk.title.historics' },
    { url: CLASSIFICATIONS_ENDPOINT, key: 'http-errors.smart-risk.title.categories' },
    { url: USER_MATRICES_ENDPOINT, key: 'http-errors.smart-risk.title.currencies' },
    { url: FX_RATES_ENDPOINT, key: 'http-errors.smart-risk.title.forex' },
    { url: EX_ANTE_ANALYSIS_ENDPOINT, key: 'http-errors.smart-risk.title.exante' },
    { url: BACKTEST_ENDPOINT, key: 'http-errors.smart-risk.title.backtest' },
  ];

  getHttpErrorTitle(requestUrl: string): string {
    const errorTitle = this._errorTitlesKeys.find(title => requestUrl.includes(title.url));
    return errorTitle ? errorTitle.key : 'http-errors.smart-risk.title.default';
  }

  formatErrorMessages(
    requestUrl: string,
    errorMessages: ErrorResponseMessages,
    type: 'errors' | 'warnings'
  ): ErrorMessageData {
    const errorMessagesData: Array<{ key: string; translations: TranslationObject[]; exlcude?: boolean }> = [];
    const errorMessagesKey = Object.keys(errorMessages);
    let titleKey: string;
    if (type === 'errors') {
      titleKey = this.getHttpErrorTitle(requestUrl);
    }
    errorMessagesKey.forEach(key => {
      errorMessages[key].forEach(errorMessage => {
        SRAPI_REGEXP.exclude.forEach(excludeRegexp => {
          const matchedExclude = errorMessage.match(new RegExp(excludeRegexp));
          if (matchedExclude) {
            errorMessagesData.push({
              key,
              translations: [],
              exlcude: true,
            });
          }
        });

        SRAPI_REGEXP[type].forEach((errorRegexp, regexpIndex) => {
          const matchedError = errorMessage.match(new RegExp(errorRegexp));
          if (matchedError) {
            const messageData = matchedError.filter((_match, matchIndex) => matchIndex !== 0);
            const existingInstrumentMessage = errorMessagesData.find(errorMessageData => errorMessageData.key === key);
            const params: Record<string, string> = {};
            messageData.forEach((dataItem, dataIndex) => {
              params[`param${dataIndex}`] = dataItem;
            });
            if (existingInstrumentMessage) {
              existingInstrumentMessage.translations.push({
                key: `http-errors.smart-risk.${type}.${regexpIndex}`,
                params,
              });
              return;
            }
            const translations: TranslationObject[] = !isNaN(parseInt(key, 10))
              ? [{ key: 'http-errors.smart-risk.instrument', params: { param0: key } }]
              : [{ key: 'http-errors.smart-risk.allocation', params: { param0: key.split('_')[1] } }];

            translations.push({ key: `http-errors.smart-risk.${type}.${regexpIndex}`, params });
            errorMessagesData.push({
              key,
              translations,
            });
          }
        });
      });
    });
    const excludedErrorMessage = errorMessagesData.filter(errorMessageItem => errorMessageItem.exlcude);
    if (excludedErrorMessage && errorMessagesData.length === excludedErrorMessage.length) {
      return { translations: [] };
    }
    if (errorMessagesData.length === 0) {
      return {
        titleKey,
        translations: [
          {
            key: type === 'errors' ? 'http-errors.smart-risk.error-default' : 'http-errors.smart-risk.warning-default',
          },
        ],
      };
    }

    // we don't want too display too many error messages so that the snackbar isn't too big
    const filteredTranslations = errorMessagesData
      .filter(errorMessageItem => !excludedErrorMessage.includes(errorMessageItem))
      .slice(0, 7)
      .reduce((translationsArray, errorMessageData) => {
        errorMessageData.translations.forEach(translation => {
          translationsArray.push(translation);
        });
        return translationsArray;
      }, []);

    return { titleKey, translations: filteredTranslations };
  }
}
