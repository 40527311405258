import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as fromEntities from '@app/core/entities/reducers';
import { Allocation, Asset, Project, TelemetryEventTypes } from '@app/core/models';
import { BackendService, SmartRiskService } from '@app/core/services';
import { ConfirmationModalComponent } from '@app/shared/components';
import { AppActions } from '@app/store/actions';
import * as fromRoot from '@app/store/reducers';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { isEqual } from 'lodash';
import { combineLatest, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import { ProjectsActions } from '../actions';

@Injectable()
export class ProjectEffects {
  addProject$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(ProjectsActions.addProject),
      withLatestFrom(this._store.select(fromEntities.getAllAssets)),
      switchMap(([action, assets]) =>
        this._backendService.addProject(action.project).pipe(
          map(addedProject => {
            const initedProject: Project = this._getInitiatedProject(addedProject, action.project, assets);

            return ProjectsActions.addProjectSuccess({ project: initedProject });
          }),
          catchError(error => {
            this._store.dispatch(ProjectsActions.addProjectFailure());
            return throwError(error);
          })
        )
      )
    );
  });

  updateProject$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(ProjectsActions.updateProject),
      withLatestFrom(this._store.select(fromEntities.getAllAssets)),
      switchMap(([action, assets]) =>
        this._backendService.updateProject(action.project).pipe(
          map(updatedProject => {
            const initedProject: Project = this._getInitiatedProject(updatedProject, action.project, assets);

            return ProjectsActions.updateProjectSuccess({
              project: { id: initedProject.id, changes: initedProject },
            });
          }),
          catchError(error => {
            this._store.dispatch(ProjectsActions.updateProjectFailure());
            return throwError(error);
          })
        )
      )
    );
  });

  upsertProjectSuccess$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(ProjectsActions.addProjectSuccess, ProjectsActions.updateProjectSuccess),
      map(() => AppActions.httpSuccess({ messageKey: { key: 'success.save-project' } }))
    );
  });

  upsertProjectFailure$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(ProjectsActions.addProjectFailure, ProjectsActions.updateProjectFailure),
      map(() => AppActions.httpFailure({ messageKey: { key: 'errors.save-project' } }))
    );
  });

  addProjectSuccess$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(ProjectsActions.addProjectSuccess),
      map(action =>
        AppActions.sendTelemetryEvent({
          telemetryData: {
            eventType: TelemetryEventTypes.projectCreation,
            tags: { financialProfile: action.project.financialProfile ? action.project.financialProfile : '' },
          },
        })
      )
    );
  });

  updateProjectArchiveStatus$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(ProjectsActions.updateProjectArchiveStatus),
      withLatestFrom(this._store.select(fromEntities.getAllAssets)),
      switchMap(([action, assets]) =>
        this._backendService.updateProject(action.project).pipe(
          map(updatedProject => {
            const initedProject: Project = this._getInitiatedProject(updatedProject, action.project, assets);

            return ProjectsActions.updateProjectArchiveStatusSuccess({
              project: { id: initedProject.id, changes: initedProject },
            });
          }),
          catchError(error => {
            this._store.dispatch(ProjectsActions.updateProjectFailure());
            return throwError(error);
          })
        )
      )
    );
  });

  updateProjectArchiveStatusSuccess$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(ProjectsActions.updateProjectArchiveStatusSuccess),
      map(({ project }) => {
        const translationKey = project.changes.isArchived ? 'success.archive-project' : 'success.unarchive-project';
        return AppActions.httpSuccess({ messageKey: { key: translationKey } });
      })
    );
  });

  saveSelectedProject$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(ProjectsActions.saveSelectedProject),
      withLatestFrom(this._store.select(fromEntities.getSelectedProject)),
      map(([_action, selectedProject]) =>
        selectedProject.id
          ? ProjectsActions.updateProject({ project: selectedProject })
          : ProjectsActions.addProject({ project: selectedProject })
      )
    );
  });

  resetSelectedProject$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(ProjectsActions.resetSelectedProject),
      withLatestFrom(
        this._store.select(fromEntities.getAllProjects),
        this._store.select(fromEntities.getSelectedProject)
      ),
      switchMap(([{ route }, projects, selectedProject]) => {
        const savedSelectedProject = projects.find(project => selectedProject && project.id === selectedProject.id);
        if (
          isEqual(this._removeUnsavedProperties(savedSelectedProject), this._removeUnsavedProperties(selectedProject))
        ) {
          return of(ProjectsActions.confirmResetSelectedProject({ route }));
        }
        return this._dialog
          .open(ConfirmationModalComponent, {
            panelClass: 'am-dialog',
            width: '50%',
            maxWidth: '600px',
            minWidth: '400px',
            data: {
              titleKey: 'shared.unsaved-project-dialog.title',
              content: { key: 'shared.unsaved-project-dialog.content' },
              confirmLabelKey: 'shared.unsaved-project-dialog.confirm-label',
              cancelLabelKey: 'shared.unsaved-project-dialog.cancel-label',
            },
          })
          .afterClosed()
          .pipe(
            switchMap(confirmValue =>
              confirmValue
                ? of(ProjectsActions.confirmResetSelectedProject({ route }))
                : of(ProjectsActions.cancelResetSelectedProject())
            )
          );
      })
    );
  });

  confirmResetSelectedProject$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(ProjectsActions.confirmResetSelectedProject),
        filter(({ route }) => route != null),
        tap(({ route }) => this._router.navigate([route]))
      ),
    { dispatch: false }
  );

  loadUserProjects$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(ProjectsActions.loadUserProjects),
      withLatestFrom(this._store.select(fromRoot.getCurrentUser)),
      switchMap(([_, currentUser]) =>
        combineLatest(
          this._backendService.getUserProjects(currentUser.nameId),
          this._store.pipe(
            select(fromEntities.getAllAssets),
            filter(assets => assets && assets.length > 0),
            take(1)
          )
        ).pipe(
          map(([userProjects, assets]) => {
            userProjects.forEach(project => {
              project.missingAssets = this._getProjectMissingAssets(project.allocations, assets);
              project.missingFinancialProfile = this._getProjectMissingFinancialProfiles(
                project.financialProfile,
                assets
              );
            });
            return ProjectsActions.loadUserProjectsSuccess({ projects: userProjects });
          }),
          catchError(error => {
            this._store.dispatch(ProjectsActions.loadUserProjectsFailure());
            return throwError(error);
          })
        )
      )
    );
  });

  loadUserProjectsFailure$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(ProjectsActions.loadUserProjectsFailure),
      map(() => AppActions.httpFailure({ messageKey: { key: 'errors.get-projects' } }))
    );
  });

  deleteProject$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(ProjectsActions.deleteProject),
      switchMap(action => {
        return this._backendService.deleteProject(action.projectId).pipe(
          map(projectId => ProjectsActions.deleteProjectSuccess({ projectId })),
          catchError(error => {
            this._store.dispatch(AppActions.httpFailure({ messageKey: { key: 'errors.delete-project' } }));
            return throwError(error);
          })
        );
      })
    );
  });

  deleteProjectSuccess$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(ProjectsActions.deleteProjectSuccess),
      map(_action => AppActions.httpSuccess({ messageKey: { key: 'success.delete-project' } }))
    );
  });

  getExAnteAnalysis$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(ProjectsActions.getExAnteAnalysis),
      withLatestFrom(this._store.select(fromEntities.getAllAssets)),
      tap(() => this._router.navigate(['analysis'])),
      switchMap(([action, assets]) => {
        return this._smartRiskService.getExAnteAnalysis(action.project, assets).pipe(
          map(analysisResult => {
            action.project.allocations.forEach(allocation => {
              allocation.exAnteAnalysis = analysisResult.find(
                analysisItem => analysisItem.allocationId === allocation.id
              );
            });
            return ProjectsActions.getExAnteAnalysisSuccess({ selectedProject: action.project });
          }),
          catchError(error => {
            this._store.dispatch(ProjectsActions.getExAnteAnalysisFailure());
            return throwError(error);
          })
        );
      })
    );
  });

  getExAnteAnalysisFailure$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(ProjectsActions.getExAnteAnalysisFailure),
        tap(() => this._router.navigate(['simulation']))
      ),
    { dispatch: false }
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store<fromRoot.State>,
    private readonly _router: Router,
    private readonly _smartRiskService: SmartRiskService,
    private readonly _backendService: BackendService,
    private readonly _dialog: MatDialog
  ) {}

  private _getInitiatedProject(addOrUpdateProject: Project, actionProject: Project, assets: Asset[]): Project {
    return {
      ...addOrUpdateProject,
      allocations: actionProject.allocations,
      missingAssets: this._getProjectMissingAssets(addOrUpdateProject.allocations, assets),
      missingFinancialProfile: this._getProjectMissingFinancialProfiles(addOrUpdateProject.financialProfile, assets),
      backtestResults: actionProject.backtestResults,
    };
  }

  private _getProjectMissingAssets(allocations: Allocation[], assets: Asset[]): boolean {
    for (const allocation of allocations) {
      if (!allocation.positions || allocation.positions.length === 0) {
        return true;
      }
      if (allocation.positions.find(position => !assets.find(asset => asset.id === position.instrumentId))) {
        return true;
      }
    }
    return false;
  }

  private _getProjectMissingFinancialProfiles(financialProfile: string, assetsList: Asset[]): boolean {
    const financialProfiles = assetsList[0].profiles.filter(profile => !profile.value.includes('-HEDGE'));
    return (
      financialProfiles.length !== 0 &&
      financialProfiles.every(financialProfileItem => financialProfileItem.value !== financialProfile)
    );
  }

  private _removeUnsavedProperties(project: Project): Project {
    return project?.allocations
      ? {
          ...project,
          allocations: project.allocations.map(allocationItem => {
            const allocation = { ...allocationItem };
            delete allocation.exAnteAnalysis;
            return allocation;
          }),
          backtestParameters: project.backtestParameters ? { ...project.backtestParameters } : undefined,
          backtestResults: undefined,
        }
      : project;
  }
}
