import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { TranslationObject } from '@app/core/models';
import { SnackBarComponent } from '@app/shared/components';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  constructor(private readonly _matSnackBar: MatSnackBar) {}

  openSnackBar(
    classPrefix: 'error' | 'success' | 'warning',
    messages?: TranslationObject[],
    title?: string,
    duration = 12000
  ): MatSnackBarRef<SnackBarComponent> {
    return this._matSnackBar.openFromComponent(SnackBarComponent, {
      duration,
      panelClass: [`${classPrefix}-snackbar`, 'am-snack-bar'],
      data: {
        title,
        messages,
        actionLabel: 'X',
      },
    });
  }
}
