import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { SrapiRequestAdapter, SrapiResponseAdapter } from '../adapters';
import { AvailableCountry } from '../const/country';
import { SMART_RISK_CONFIG } from '../const/smart-risk-config';
import {
  Asset,
  BacktestRequest,
  BacktestResponse,
  BacktestResult,
  Category,
  ClassificationResponse,
  Composition,
  CurrenciesResponse,
  ExAnteAnalysis,
  ExAnteAnalysisResponse,
  Histories,
  InstrumentsResponse,
  Project,
  Rebalancing,
} from '../models';
import { formatRequestDate } from '../tools/date.tools';
import { getInstrumentExpectedReturn, isAssetCredit } from '../tools/smart-risk.tools';

const smartRiskUrl = environment.base_smart_url;
const DATACONTEXT_ENDPOINT = `${smartRiskUrl}datacontext/csv`;
export const INSTRUMENTS_ENDPOINT = `${DATACONTEXT_ENDPOINT}/instruments`;
export const INSTRUMENTS_VALUES_ENDPOINT = `${DATACONTEXT_ENDPOINT}/instruments/values`;
export const CLASSIFICATIONS_ENDPOINT = `${DATACONTEXT_ENDPOINT}/classifications`;
export const USER_MATRICES_ENDPOINT = `${DATACONTEXT_ENDPOINT}/user-matrices`;
export const FX_RATES_ENDPOINT = `${DATACONTEXT_ENDPOINT}/fxrate/values`;
export const EX_ANTE_ANALYSIS_ENDPOINT = `${smartRiskUrl}exante-analysis`;
export const BACKTEST_ENDPOINT = `${smartRiskUrl}portfolio-backtest`;

@Injectable({
  providedIn: 'root',
})
export class SmartRiskService {
  constructor(private readonly _httpClient: HttpClient) {}

  getAssets(country: AvailableCountry): Observable<Asset[]> {
    return this._httpClient
      .get<InstrumentsResponse>(INSTRUMENTS_ENDPOINT)
      .pipe(map(srResponse => SrapiResponseAdapter.instrumentResponseToAsset(srResponse, country)));
  }

  getAssetsHistories(): Observable<Histories[]> {
    return this._httpClient
      .get<{ instrumentsRange: Histories[] }>(INSTRUMENTS_VALUES_ENDPOINT)
      .pipe(map(srResponse => srResponse.instrumentsRange));
  }

  getCategories(): Observable<Category[]> {
    return this._httpClient
      .get<ClassificationResponse>(CLASSIFICATIONS_ENDPOINT)
      .pipe(map(srResponse => SrapiResponseAdapter.classificationResponseToCategory(srResponse)));
  }

  getAllCurrencies(): Observable<string[]> {
    return this._httpClient
      .get<CurrenciesResponse>(USER_MATRICES_ENDPOINT)
      .pipe(map(srResponse => SrapiResponseAdapter.currenciesResponseToCurrencies(srResponse)));
  }

  getFxRatesHistories(): Observable<Histories[]> {
    return this._httpClient
      .get<{ instrumentsRange: Histories[] }>(FX_RATES_ENDPOINT)
      .pipe(map(srResponse => srResponse.instrumentsRange));
  }

  getExAnteAnalysis(project: Project, assets: Asset[]): Observable<ExAnteAnalysis[]> {
    const views: Array<{ name: string; value: number }> = [];
    const classificationCompositions: Composition[] = [];
    assets.forEach((asset: Asset) => {
      const viewValue = this._getInstrumentsViewsValue(asset, project);
      if (viewValue !== null) {
        views.push({
          name: asset.id.toString(),
          value: this._getInstrumentsViewsValue(asset, project),
        });
      }
      classificationCompositions.push({
        instrumentId: asset.id.toString(),
        elements: [
          {
            classId: asset.liquidity.en,
            weight: 1,
          },
        ],
      });
    });
    const requestBody = {
      portfolios: project.allocations.map(alloc =>
        SrapiRequestAdapter.allocationToPortfolioRequest(alloc, project.currency)
      ),
      parameters: {
        riskMeasures: ['volatility', 'normalVar'],
        riskEstimationWindow: 350,
        returnEstimationWindow: 350,
        portfolioHedge: project.hedge,
        riskHorizon: 1,
        returnHorizon: 1,
        viewsHorizon: 1,
        levelOfConfidence: 'percent95',
        filteringLevel: 0.01,
        covarianceEstimationMethod: 'UserMatrix',
        UserCovarianceMatrixSource: 'csv',
        UserMatrixId: `${project.currency}${project.hedge ? '-HEDGED' : ''}`,
        returnMethod: 'views',
        views,
        analysisClassifications: ['Liquidity'],
      },
      classifications: [{ classificationId: 'Liquidity', composition: classificationCompositions }],
    };
    return this._httpClient
      .post<ExAnteAnalysisResponse[]>(EX_ANTE_ANALYSIS_ENDPOINT, requestBody)
      .pipe(
        map(srResponse =>
          SrapiResponseAdapter.exAnteAnalysisResponseToResult(
            project,
            assets,
            srResponse,
            views.find(view => SMART_RISK_CONFIG.asset.cashIds.includes(parseInt(view.name, 10))).value
          )
        )
      );
  }

  getBacktest(project: Project): Observable<BacktestResult[]> {
    const parameterStartDate = formatRequestDate(moment(project.backtestParameters.startDate).startOf('month'));
    const rebalancing: Rebalancing[] = [];

    const portfolios = project.allocations.map(allocation => {
      const portfolio = SrapiRequestAdapter.allocationToPortfolioRequest(
        allocation,
        project.currency,
        project.backtestParameters.benchmark
      );
      const startDate = moment(project.backtestParameters.startDate);
      const endDate = moment(project.backtestParameters.endDate);
      const firstOfJanuary = moment(project.backtestParameters.startDate).startOf('year');
      if (firstOfJanuary.isSameOrBefore(startDate)) {
        firstOfJanuary.add(1, 'years');
      }

      while (firstOfJanuary.isSameOrAfter(startDate) && firstOfJanuary.isSameOrBefore(endDate)) {
        rebalancing.push({
          portfolioId: portfolio.portfolioId,
          rebalancingDate: formatRequestDate(firstOfJanuary),
          rebalancingMethod: 'initialWeights',
        });
        firstOfJanuary.add(1, 'years');
      }
      return portfolio;
    });
    portfolios.push(
      SrapiRequestAdapter.allocationToPortfolioRequest(project.backtestParameters.benchmark, project.currency)
    );

    const requestBody: BacktestRequest = {
      portfolios,
      portfolioAsOfDate: rebalancing.length > 0 ? 'startDate' : 'endDate',
      simulationMode: 'constantShares',
      parameters: {
        startDate: parameterStartDate,
        endDate: formatRequestDate(moment(project.backtestParameters.endDate).endOf('month')),
        portfolioHedge: project.hedge,
        exPostMeasures: ['maxDrawdown', 'maxDrawdownRecovery'],
        levelOfConfidence: 'percent95',
        computeInstrumentValues: false,
        computeBenchmark: false,
        computeBenchmarkInstrumentValues: false,
        benchmarkHedge: project.hedge,
        rebalancingCalendar: rebalancing,
      },
    };

    return this._httpClient
      .post<BacktestResponse[]>(BACKTEST_ENDPOINT, requestBody)
      .pipe(map(backtestResponse => SrapiResponseAdapter.backtestResponseToResult(backtestResponse)));
  }

  private _getInstrumentsViewsValue(asset: Asset, project: Project): number {
    return isAssetCredit(asset) && project.creditRate !== 0
      ? project.creditRate / 100
      : getInstrumentExpectedReturn(asset, project.currency, project.hedge);
  }
}
